import { FC, memo } from 'react';
import Lottie from 'lottie-react';
import animation from './loading.json';

import { SpinnerWrapper } from './Spinner.styles';

export type SpinnerProps = {
  fullScreen?: boolean;
};

const Spinner: FC<SpinnerProps> = ({ fullScreen }) => {
  if (fullScreen) {
    return (
      <SpinnerWrapper>
        <Lottie loop animationData={animation} />
      </SpinnerWrapper>
    );
  }

  return <Lottie loop animationData={animation} />;
};

export default memo(Spinner);
