import { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import { APP_ROUTES } from 'constants/routes';
import { CACHE_KEYS } from 'constants/cache';

import { authApi } from 'api_entities/auth';
import { userApi } from 'api_entities/user';
import { UserProfile } from 'api_entities/user/types';
import { SocialAuthContext } from '../../../context/SocialAuthProvider/SocialAuthContext';
import { SocialNetworkTokenStateType } from '../../../context/SocialAuthProvider/types';

const facebookScope = 'public_profile,email';

export const useAuthViaSocialNetwork = () => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const [error, setError] = useState<string>('');

  const context = useContext(SocialAuthContext);

  if (context === undefined) {
    throw new Error('useAuthViaSocialNetwork must be used within a SocialAuthContextProvider');
  }

  const { setSocialTokenState, tokenState, googleClient } = context;

  const fetchProfile = async () => {
    try {
      return await queryClient.fetchQuery<UserProfile>(CACHE_KEYS.USER, userApi.getUser);
    } catch (e) {
      return;
    }
  };

  const loginViaGoogle = useCallback(() => {
    setError((prevState) => (prevState ? '' : prevState));

    if (googleClient) {
      googleClient.requestAccessToken();
    }
  }, [googleClient, setError]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const logoutViaGoogle = useCallback(() => {}, [googleClient]);

  const loginViaFacebook = useCallback(() => {
    setError((prevState) => (prevState ? '' : prevState));

    window.FB?.login(
      (result) => {
        if (result.status === 'connected') {
          setSocialTokenState({ type: 'facebook', token: result.authResponse.accessToken });
        }
      },
      { scope: facebookScope }
    );
  }, [setError, setSocialTokenState]);

  const logoutViaFacebook = useCallback(() => {
    window.FB?.logout();
  }, []);

  useEffect(() => {
    if ((tokenState?.type && tokenState?.token)) {
      const tryAuthUser = async ({ token, type }: SocialNetworkTokenStateType) => {
        try {
          await authApi.loginViaSocialNetwork(token, type);
        } catch (err: any) {
          if ((err?.status === 400 || err?.status === 401) && err?.data?.message) {
            setError(err?.data?.message);
          }

          return;
        }

        const profile = await fetchProfile();

        if (profile && !profile?.onboardAt) {
          await router.replace({ pathname: APP_ROUTES.ONBOARDING, query: router.query });
        } else {
          await router.replace(
            typeof router.query.from === 'string' ? router.query.from : APP_ROUTES.HOME
          );
        }
      };

      tryAuthUser(tokenState).catch((e) => console.error(e));
    }
  }, [tokenState?.type, tokenState?.token]);

  return useMemo(
    () => ({ loginViaGoogle, logoutViaGoogle, loginViaFacebook, logoutViaFacebook, error }),
    [loginViaGoogle, logoutViaGoogle, loginViaFacebook, logoutViaFacebook, error]
  );
};
