import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useQueryClient } from 'react-query';
import { UserProfile } from 'api_entities/user/types';

import { APP_ROUTES } from 'constants/routes';
import { CACHE_KEYS } from 'constants/cache';

export type UseNonAuthGuardParams = {
  redirectTo?: string;
};

export type UseNonAuthGuardReturn = {
  isUserAuth: boolean;
};

export const useNonAuthGuard = (params?: UseNonAuthGuardParams): UseNonAuthGuardReturn => {
  const router = useRouter();
  const queryClient = useQueryClient();

  const isUserAuth = Boolean(queryClient.getQueryData<UserProfile>(CACHE_KEYS.USER)?.objectID);

  useEffect(() => {
    if (isUserAuth) {
      const redirectTo = params?.redirectTo || APP_ROUTES.HOME;

      router.replace(redirectTo);
    }
  }, []);

  return { isUserAuth };
};
